<template>
  <div class="wrapper-inner">
    <div class="inner-content">
      <div class="wrapper-notice">
        <h2>Покупка подарочного сертификата</h2>
        <div class="wrapper-sertificate-pay">
          <validation-observer
            ref="observer"
            tag="div"
            class="sertificate-pay-form"
          >
            <form @submit.prevent="payCert">
              <radio-btn-group :items="certIds" :model.sync="formData.id" />
              <radio-btn-group :items="certType" :model.sync="formData.type" />
              <validation-provider
                name="ФИО"
                tag="div"
                class="input-reg-area"
                rules="required"
                v-slot="{ errors, reset }"
              >
                <label for="name">Кому дарите сертификат?*</label>
                <input
                  id="name"
                  type="text"
                  v-model="formData.name"
                  :class="errors.length ? 'error-input' : ''"
                  placeholder="Введите ФИО"
                  maxlength="28"
                  required
                />
                <error-pop-over :errors="errors" :reset-function="reset" />
              </validation-provider>
              <validation-provider
                name="Комментарий"
                tag="div"
                class="input-reg-area"
                v-slot="{ errors, reset }"
              >
                <label for="comment">
                  Напишите комментарий на сертификате
                </label>
                <textarea
                  id="comment"
                  v-model="formData.comment"
                  maxlength="45"
                  :class="errors.length ? 'error-input' : ''"
                  placeholder="Например, для партнера"
                ></textarea>
                <error-pop-over :errors="errors" :reset-function="reset" />
                <label>
                  Срок активации сертификата - 3 месяца со дня приобретения
                </label>
              </validation-provider>
              <input type="submit" value="Подарить сертификат" />
            </form>
          </validation-observer>
          <div class="sertificate-pay-img">
            <horizontal
              :length.sync="currentCertLength"
              :whom="formData.name"
              :message="formData.comment"
              :cert-id="formData.id"
              v-if="formData.type === 'horizontal'"
            />
            <vertical
              :whom="formData.name"
              :length.sync="currentCertLength"
              :cert-id="formData.id"
              :message="formData.comment"
              v-else
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { messages } from "vee-validate/dist/locale/ru.json";
import { required } from "vee-validate/dist/rules";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
import RadioBtnGroup from "@/components/page/items/radio/RadioBtnGroup";
import { mapGetters, mapActions } from "vuex";
import Horizontal from "@/components/page/certs/items/horizontal";
import Vertical from "@/components/page/certs/items/vertical";

extend("required", {
  ...required,
  message: messages["required"]
});

export default {
  name: "CertPay",
  components: {
    Vertical,
    Horizontal,
    RadioBtnGroup,
    ValidationObserver,
    ValidationProvider,
    ErrorPopOver
  },
  props: ["promocode"],
  data() {
    return {
      certType: [
        {
          name: "type",
          text: "Вертикальный",
          value: "vertical"
        },
        {
          name: "type",
          text: "Горизонтальный",
          value: "horizontal"
        }
      ],
      formData: {
        name: "",
        comment: "",
        id: this.$route.params.id,
        type: "horizontal"
      }
    };
  },
  computed: {
    ...mapGetters(["certs"]),
    certIds() {
      return this.certs.reduce(
        (acc, curr) =>
          acc.concat({
            name: "id",
            text: curr.length,
            value: curr.id
          }),
        []
      );
    },
    currentCertLength() {
      const d = this.certs.find(x => x.id === this.formData.id);
      return d ? d.length : "";
    }
  },
  methods: {
    ...mapActions(["getCerts"]),
    payCert() {
      this.$router.push({
        name: "Payment",
        query: {
          payment: "cert",
          goodId: this.formData.id,
          addName: this.formData.name,
          addComment: this.formData.comment,
          addType: this.formData.type.toUpperCase()
        }
      });
    }
  },
  created() {
    this.getCerts();
  },
  watch: {
    "formData.id"(n, o) {
      if (n !== o) {
        this.$router.replace({ params: { id: n } });
      }
    }
  }
};
</script>

<style scoped></style>
