<template>
  <slick ref="slick" :options="slickOptions" :class="moduleClass">
    <state-item
      v-for="tile in moduleData"
      item-class="news-tovar-items"
      v-bind:key="tile.id"
    >
      <h4>
        <a href="/" v-html="tile.name"></a>
      </h4>
    </state-item>
  </slick>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
import Slick from "vue-slick";

export default {
  name: "Module",
  props: {
    moduleClass: {
      type: String,
      default: "slider-news-modul"
    },
    slidesToShow: {
      type: Number,
      default: 3
    },
    moduleData: {
      type: Array
    }
  },
  components: { StateItem, Slick },
  data() {
    return {
      slickOptions: {
        infinite: true,
        dots: false,
        slidesToShow: this.slidesToShow,
        slidesToScroll: 1,
        rows: 0,
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style scoped></style>
