<template>
  <div class="sidebar-noneoverflow" v-if="admin == 1">
    <!--  BEGIN NAVBAR  -->
    <admin-header :search-params.sync="searchValue" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <!--  BEGIN SIDEBAR  -->
      <sidebar />
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div>
          <router-view :search-params="searchValue"></router-view>
        </div>
      </div>
      <!--  END CONTENT AREA  -->
    </div>
    <!-- END MAIN CONTAINER -->
  </div>
</template>
<script>
try {
  window.Popper = require("popper.js").default;
  window.$ = window.jQuery = require("jquery");
  require("bootstrap");
} catch (e) {
  console.log(e);
}
import AdminHeader from "@/components/admin/AdminHeader";
import Sidebar from "@/components/admin/sidebar/Sidebar";
export default {
  name: "Admin",
  components: { Sidebar, AdminHeader },
  data() {
    return {
      formData: this.$auth.user() || {},
      searchValue: "",
      admin: 0,
      check_mail: ""
    };
  },
  mounted() {
    this.check_mail = this.formData.email;
    if (String(this.check_mail) == "hello@mediateka.pro") {
      this.admin += 1;
    }
  },
  computed: {
    user: {
      get() {
        return this.formData;
      },
      set(n) {
        this.formData = n;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/dash_1.css";
@import "../../assets/main.css";
@import "../../assets/structure.css";
@import "~bootstrap/scss/bootstrap";
</style>
