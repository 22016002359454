import Vue from "vue";
class Payment {
  TermUrl = "https://mediateka.pro/v1/payments/redir";
  getPaymentConfig() {
    return Vue.axios.get("/payments/config").then(response => {
      const newdata = {
        publicId: "pk_97199a8932b4fb3aa69c66a59d3a2",
        merchantId: "200000000000701",
        googleId: "BCR2DN6TSOYKT4Q6"
      };
      console.log(response.data);
      return newdata;
      // return response.data;
    });
  }
  loadComponent(id, options) {
    if (document.getElementById(id)) return;
    const opts = options || {};
    const scriptTag = document.createElement("script");
    scriptTag.src = opts.src;
    scriptTag.async = opts.async || false;
    scriptTag.id = id;
    if (opts.onload) {
      scriptTag.onload = opts.onload;
    }
    if (opts.body) {
      document.body.appendChild(scriptTag);
    } else {
      document.getElementsByTagName("head")[0].appendChild(scriptTag);
    }
  }
  unloadComponent(id, options) {
    const opts = options || {};
    const apiDoc = document.getElementById(id);
    if (apiDoc) {
      if (opts.body) {
        document.body.removeChild(apiDoc);
      } else {
        document.getElementsByTagName("head")[0].removeChild(apiDoc);
      }
    }
  }
  submittedDownloadForm(e) {
    console.log(e);
  }
  sendFormConfirm(acsFormData) {
    const form = document.createElement("form");
    form.action = acsFormData.acsUrl;
    form.method = "post";
    form.name = "downloadForm";
    form.onsubmit = this.submittedDownloadForm;
    const transactionId = document.createElement("input");
    transactionId.name = "MD";
    transactionId.type = "hidden";
    transactionId.value = acsFormData.transactionId;
    const termUrl = document.createElement("input");
    termUrl.name = "TermUrl";
    termUrl.type = "hidden";
    termUrl.value = acsFormData.TermUrl;
    const paReq = document.createElement("input");
    paReq.name = "PaReq";
    paReq.type = "hidden";
    paReq.value = acsFormData.paReq;
    form
      .appendChild(paReq)
      .appendChild(transactionId)
      .appendChild(termUrl);
    document.body.append(form);
    form.submit();
  }
  acquire(url, packet, data) {
    return Vue.axios.post(url, null, {
      params: {
        ...packet,
        ...data
      }
    });
  }
  acquireData(url, packet, data) {
    return Vue.axios.post(url, {
      ...packet,
      ...data
    });
  }
  acquireRequest(url, packet, data) {
    this.acquire(url, packet, data).then(this.acquireRequestThen);
  }
  acquireRequestThen = response => {
    const secure3DSdata = response.data;
    const model = secure3DSdata.model;
    const acsFormData = {
      ...model,
      TermUrl: this.TermUrl || secure3DSdata.termUrl
    };
    this.sendFormConfirm(acsFormData);
  };
}

export default new Payment();
