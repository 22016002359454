<template>
  <div class="promocod-bg">
    <header>
      <div class="top-left">
        <div class="logo" style="z-index: 999999;">
          <router-link to="/">
            <img src="@/assets/img/logoheader.svg" alt="logo" />
          </router-link>
        </div>
      </div>
    </header>

    <div class="wrapper-inner">
      <div class="inner-content center-content">
        <h4 class="header-promo">Промокод</h4>
        <p class="text-promo">
          Введите промокод для доступа к развивающему видеоконтенту в высоком
          качестве.
        </p>
        <validation-observer ref="observer">
          <form @submit.prevent="promoAccept">
            <div class="form-promo-b">
              <validation-provider
                name="промокод"
                ref="promo"
                rules="required"
                tag="div"
                class="input-promo-text"
                v-slot="{ errors, reset }"
              >
                <label for="inputPromo">Введите промокод </label>
                <input
                  id="inputPromo"
                  v-model="formData.code"
                  :class="{ 'error-input': errors.length }"
                  type="text"
                  required
                />
                <error-pop-over :reset-function="reset" :errors="errors" />
              </validation-provider>
              <div class="input-promo-submit">
                <input type="submit" value="Активировать" />
              </div>
            </div>
          </form>
        </validation-observer>
        <modal-alert
          :alert-text="errorTextAlert"
          action-text="Продолжить"
          :action-modal="closeAlert"
          :show-modal.sync="showAlert"
        />
      </div>
    </div>
    <site-section section-class="footer-absolute">
      <template v-slot:content>
        <div class="dflexnonemobile">
          <ul class="bottom-footer-copy">
            <li><a>© 2020-2022 mediateka.pro</a></li>
            <li>
              <a href="/useragreement_mediateka" target="_blank">
                Пользовательское соглашение
              </a>
            </li>
            <li>
              <a href="/privacypolicy_mediateka" target="_blank">
                Политика конфиденциальности
              </a>
            </li>
            <li>
              <a href="mailto:hello@mediateka.pro">hello@mediateka.pro</a>
            </li>
          </ul>
        </div>
        <div class="dreadymobile">
          <div style="display: inline-grid !important;">
            <a class="lastfootertext">© 2020-2022 mediateka.pro</a>
            <a
              class="lastfootertext"
              href="/useragreement_mediateka"
              target="_blank"
            >
              Пользовательское соглашение
            </a>
            <a
              class="lastfootertext"
              href="/privacypolicy_mediateka"
              target="_blank"
            >
              Политика конфиденциальности
            </a>
            <a class="lastfootertext" href="mailto:hello@mediateka.pro"
              >hello@mediateka.pro</a
            >
          </div>
        </div>

        <div class="displayblock">
          <div
            class="modal-mask"
            style="width: 0% !important; height: 0% !important;"
          >
            <form @submit.prevent="signUp">
              <input
                v-model.trim="name"
                type="hidden"
                placeholder="Имя и фамилия"
              />
              <input v-model.trim="email" type="hidden" />
              <input v-model.trim="city" type="hidden" />

              <input v-model.trim="phone" type="hidden" />

              <input v-model.trim="password" type="hidden" />
              <input v-model.trim="confirm_password" type="hidden" />
            </form>
          </div>
        </div>
      </template>
    </site-section>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { required, email } from "vee-validate/dist/rules";
// import emailjs from 'emailjs-com';
import SiteSection from "@/components/page/SiteSection";
import ModalAlert from "@/components/page/modals/forms/ModalAlert";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
// import MaskedInput from "vue-masked-input";
extend("required", {
  ...required,
  message: 'Введите данные в поле "{_field_}"'
});

extend("email", {
  ...email,
  message: 'В поле "{_field_}" должен быть действительный электронный адрес'
});

extend("is", {
  params: ["target"],
  validate(value, { target }) {
    return value === !!target;
  },
  message: "Подтвердите действие"
});

export default {
  name: "Promocode",
  components: {
    ModalAlert,
    SiteSection,
    ErrorPopOver,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      formData: {
        code: ""
      },
      showmodalnew: 0,
      charset: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      charsetName: "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ",
      charsetNumber: "0123456789",
      errorText: "",
      errorTextAlert: "",
      generetpassord: "",
      showAlert: false,
      success: false,
      accept_terms: 0
    };
  },
  mounted() {
    this.generetNumber();
    this.generetPassword();
    this.generetName();

    setTimeout(() => {
      if (this.isAuth()) {
        console.log("Вы авторизированы!");
      } else {
        this.signUp();
      }
    }, 1000);
  },
  methods: {
    ...mapMutations([
      "setFormName",
      "setEmail",
      "setPhone",
      "setCity",
      "setFormPassword",
      "setFormConfirmPassword"
    ]),
    ...mapActions(["signUpUser"]),
    ...mapGetters(["forms"]),
    signUp() {
      // const dataemail = {
      //   email: this.email,
      //   generetpassord: this.generetpassord,
      // }
      // emailjs.send('service_muczpfc', 'template_d3480bi', dataemail, 'user_Q9kmbGe8l1CpAlCw6hOx0')
      // .then(() => {
      //     alert('Данные для входа в аккаунт, отправленны на вашу почту, введите промокод')
      // }, (error) => {
      //     console.log('FAILED...', error.text);
      // });
      this.signUpUser()
        .then(() => {
          console.log("Good");
        })
        .catch(data => {
          // this.errorText = errors[data.error];
          console.log(data);
        });
    },
    isAuth() {
      return this.$auth.check();
    },
    generetNumber() {
      var n = this.charset.length;
      for (var i = 0; i < 4; i++) {
        this.phone += this.charset.charAt(Math.floor(Math.random() * n));
      }
    },
    generetPassword() {
      var n = this.charset.length;
      for (var i = 0; i < 8; i++) {
        this.generetpassord += this.charset.charAt(
          Math.floor(Math.random() * n)
        );
      }
      this.password = this.generetpassord;
      this.confirm_password = this.generetpassord;
      this.city = "Город";
    },
    generetName() {
      var n = this.charsetName.length;
      for (var i = 0; i < 8; i++) {
        this.name += this.charsetName.charAt(Math.floor(Math.random() * n));
      }
      this.email = this.generetpassord + this.phone + "@mail.ru";
    },
    promoAccept() {
      this.axios
        .post("/payments/promo/enter", null, {
          params: this.formData
        })
        .then(response => {
          this.success = response.data;
          this.showAlert = true;
          console.log(this.showAlert);
          this.errorTextAlert = this.success
            ? "Промокод успешно применен!"
            : "Неверный промокод!";
        })
        .catch(() => {
          this.applyFail(
            "Такого промокода не существует, попробуйте ввести другой"
          );
        });
    },
    applyFail(message) {
      this.$refs.promo.applyResult({
        errors: [].concat(message),
        valid: false,
        failedRules: {}
      });
    },
    closeAlert() {
      if (this.success) {
        this.$router.push({
          name: "Payment",
          query: {
            goodId: 1,
            payment: "sub",
            promo: this.formData.code,
            promoPaynew: 1,
            password: this.generetpassord
          }
        });
      } else {
        this.showAlert = false;
      }
    }
  },
  computed: {
    ...mapGetters(["forms"]),
    name: {
      get() {
        return this.forms.name;
      },
      set(value) {
        this.setFormName({ name: value });
      }
    },
    email: {
      get() {
        return this.forms.email;
      },
      set(value) {
        this.setEmail({ email: value });
      }
    },
    city: {
      get() {
        return this.forms.city;
      },
      set(value) {
        this.setCity({ city: value });
      }
    },
    phone: {
      get() {
        return this.forms.phone;
      },
      set(value) {
        this.setPhone({ phone: value });
      }
    },
    password: {
      get() {
        return this.forms.password;
      },
      set(value) {
        this.setFormPassword({ password: value });
      }
    },
    confirm_password: {
      get() {
        return this.forms.confirm_password;
      },
      set(value) {
        this.setFormConfirmPassword({ confirm_password: value });
      }
    }
  }
};
</script>

<style scoped>
.form-block {
  width: 100%;
}
.form-header {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin-top: 18px;
  font-family: Roboto, sans-serif;
}

.displayblock {
  opacity: 0;
}

.displaynone {
  display: none !important;
}
.modal-header {
  display: flex;
  justify-content: center !important;
}

.dflexnonemobile {
  display: flex;
  z-index: 9999 !important;
}

.dreadymobile {
  display: none;
  z-index: 9999 !important;
}

@media screen and (max-width: 768px) {
  .dflexnonemobile {
    display: none !important;
  }

  .dreadymobile {
    margin-top: 60px !important;
    display: block !important;
  }
}
</style>
