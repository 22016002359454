<template>
  <linked-block
    header="Смотрят сейчас"
    link-to="/novelty"
    section-class="news-tovar"
  >
    <videos :slides-to-show="4" :tiles="media" v-if="media && media.length" />
  </linked-block>
</template>

<script>
import Videos from "@/components/page/slick/Videos";
import LinkedBlock from "@/components/page/main/LinkedBlock";
export default {
  name: "NoveltyBlock",
  components: { LinkedBlock, Videos },
  props: {
    media: Array
  }
};
</script>

<style scoped></style>
