import { render, staticRenderFns } from "./ModuleLearningBlock.vue?vue&type=template&id=b83b1274&scoped=true&"
import script from "./ModuleLearningBlock.vue?vue&type=script&lang=js&"
export * from "./ModuleLearningBlock.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b83b1274",
  null
  
)

export default component.exports