<template>
  <state-item
    :item-class="itemClass"
    :add-class="tile.isFull ? 'full-states' : ''"
  >
    <div
      class="states-wrapper"
      @click.prevent="
        $router.push({ name: 'SingleArticle', params: { id: tile.id || '0' } })
      "
    >
      <div class="states-wrapper-background">
        <img :src="tile.coverUrl" :alt="tile.title" />
      </div>
      <div class="states-wrapper-content">
        <router-link
          :to="{ name: 'SingleArticle', params: { id: tile.id || '0' } }"
          :class="imgClass"
        ></router-link>
        <h4>
          <router-link
            :to="{ name: 'SingleArticle', params: { id: tile.id || '0' } }"
            v-html="tile.title"
          ></router-link>
        </h4>
        <!--         <tags :tags="tile.categories"></tags> -->
      </div>
    </div>
  </state-item>
</template>

<script>
// import Tags from "@/components/page/items/tags/Tags";
import StateItem from "@/components/page/slick/StateItem";
export default {
  name: "ArticleTile",
  components: { StateItem },
  props: {
    tile: Object,
    itemClass: {
      type: String,
      default: "news-states-items"
    },
    imgClass: {
      type: String,
      default: "states-items-img"
    }
  }
};
</script>

<style scoped></style>
