<template>
  <site-section section-class="main-banners wow fadeInUp">
    <template v-slot:content>
      <div class="main-banners-item">
        <!--       <div class="firstbackblock"></div>
       <div class="secondbackblock"></div>   -->
        <div class="main-banners-content">
          <div class="banner-main-content">
            <h4>
              Видео в качестве Full HD 1080
            </h4>
            <br />
            <p class="ptextnew">
              Идеальная картинка даст больше удовольствия от <br />
              просмотра.
            </p>
            <div class="banner-main-text" v-if="false">
              Весь контент на 1 день всего за 1
              <span class="acsecc-rub">₽</span> по
              промокоду:&nbsp;&nbsp;&nbsp;&nbsp;<copy-component />
            </div>
            <router-link to="/sub" class="podpiskanewmob">
              Попробовать
            </router-link>
            <br /><br /><br /><br />
            <router-link to="/sub" class="podpiskanew">
              Смотреть
            </router-link>
          </div>
          <!--           <img
            class="banner-main-absolute"
            src="@/assets/img/pic_media.png"
            alt=""
          /> -->
        </div>
      </div>
    </template>
  </site-section>
</template>

<script>
import SiteSection from "@/components/page/SiteSection";
import CopyComponent from "@/components/page/items/copy/CopyComponent";
export default {
  name: "MainBannersBlock",
  components: { CopyComponent, SiteSection }
};
</script>

<style scoped></style>
