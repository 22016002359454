<template>
  <state-item item-class="news-podpiska-items">
    <router-link
      :to="{ name: 'Payment', query: { goodId: data.id, payment: 'sub' } }"
    >
      <div :class="'news-podpiska-wrappernew' + ' ' + 'bdsubcs' + data.id">
        <h4>
          Подписка на
          <span class="btnlighttype">
            {{ data.type }}
          </span>
        </h4>
        <div class="news-podpiska-pre" v-if="data.id != 1">
          Более 1 000 часов бизнес-контента <br />
          Просмотр на любом устройстве <br />Лучшее качество записи
          <br />Отсутствие рекламы <br />Выгода по спецпредложению
        </div>
        <div class="news-podpiska-pre" v-else>
          Более 1 000 часов бизнес-контента <br />
          Просмотр на любом устройстве <br />Лучшее качество записи
          <br />Отсутствие рекламы
          <br />
          <br />
        </div>
        <!--       <list :data="data.list" /> -->
        <div :class="'news-podpiska-price' + ' ' + 'dopmargin' + data.id">
          <div v-if="data.id != 1">
            <span v-if="data.next">{{ data.next }}</span>
          </div>
          <div v-else>
            <br />
          </div>

          <strong> {{ data.price }} ₽ / {{ data.type }}</strong>
        </div>
      </div>
    </router-link>
  </state-item>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
// import List from "@/components/page/items/list/List";
export default {
  name: "SubItem",
  components: { StateItem },
  props: {
    data: Object
  }
};
</script>

<style scoped></style>
