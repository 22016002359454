import Vue from "vue";
import util from "@/store/util";
const default_signup = () => ({
  formData: {
    email: "",
    phone: "",
    password: "",
    confirm_password: "",
    name: "",
    country: "",
    city: "",
    birthday: ""
  },
  notifications: {
    content: undefined,
    news: undefined,
    recommendation: undefined,
    sales: undefined
  },
  users: [],
  usersForList: []
});

const signup = {
  state: default_signup(),
  getters: {
    forms: state => state.formData,
    notifications: state => state.notifications,
    users: state => state.users,
    usersForList: state =>
      state.usersForList.reduce((acc, curr) => acc.concat(curr.email), [])
  },
  mutations: {
    setEmail(state, payload) {
      state.formData.email = payload.email;
    },
    setPhone(state, payload) {
      state.formData.phone = payload.phone;
    },
    setFormPassword(state, payload) {
      state.formData.password = payload.password;
    },
    setFormConfirmPassword(state, payload) {
      state.formData.confirm_password = payload.confirm_password;
    },
    setFormName(state, payload) {
      state.formData.name = payload.name;
    },
    setCountry(state, payload) {
      state.formData.country = payload.country;
    },
    setCity(state, payload) {
      state.formData.city = payload.city;
    },
    setBirthday(state, payload) {
      state.formData.birthday = payload.birthday;
    },
    // eslint-disable-next-line no-unused-vars
    resetForm(state) {
      Object.assign(state, default_signup());
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    },
    setUsers: util.mutations.setData,
    appendUsers: util.mutations.appendData
  },
  actions: {
    signUpUser({ state, commit }) {
      let data = Object.assign({}, state.formData);
      // data.birthday = Vue.moment(data.birthday, "DD.MM.YYYY").format(
      //   "YYYY-MM-DD"
      // );
      return new Promise((resolve, reject) => {
        Vue.auth
          .register({
            data: data
          })
          .then(() => {
            commit("resetForm");
            Vue.auth.login({
              data: {
                email: data.email,
                password: data.password
              },
              redirect: null,
              fetchUser: true,
              rememberMe: true
            });
            // .then(() => {
            //   const redirect = Vue.auth.redirect();
            //   const redirectTo = redirect
            //     ? redirect.from.name
            //     : "UserProfile";
            //   resolve({ name: redirectTo });
            // });
          })
          .catch(error => {
            const response = error.response;
            reject(response ? response.data : { message: "Произошла ошибка!" });
          });
      });
    },
    getNotifications({ commit }) {
      return new Promise(resolve => {
        Vue.axios.get("/auth/notification/info").then(response => {
          commit("setNotifications", response.data);
          resolve(response.data);
        });
      });
    },
    updateNotifications({ commit }, payload) {
      return new Promise(resolve => {
        Vue.axios.post("/auth/notification/update", payload).then(response => {
          commit("setNotifications", response.data);
          resolve(response.data);
        });
      });
    },
    getFilteredUsers({ dispatch }, data) {
      return dispatch("getUsersWithFilter", {
        method: "/auth/info/list",
        type: data.type,
        params: {
          size: data.size,
          search: data.search,
          page: data.page
        }
      });
    },
    getUsersWithFilter({ commit }, data) {
      if (!data.commitMethod) {
        data.commitMethod = "setUsers";
      }
      return util.methods.getFilteredObjects(commit, data, {
        flushMethod: "setUsers"
      });
    }
  }
};

export default signup;
