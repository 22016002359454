<template>
  <slick ref="slicks" :options="slickOptions" :class="slickStyle.slickClass">
    <article-tile
      :item-class="slickStyle.itemClass"
      :img-class="slickStyle.imgClass"
      v-for="tile in tiles"
      v-bind:key="tile.key"
      :tile="tile"
    />
  </slick>
</template>

<script>
import Slick from "vue-slick";
import ArticleTile from "@/components/page/articles/tiles/ArticleTile";

export default {
  name: "Articles",
  components: { ArticleTile, Slick },
  props: {
    tiles: Array,
    articlesToShow: {
      type: Number,
      default: 3
    },
    slickStyle: {
      type: Object,
      default() {
        return {
          itemClass: "news-states-items",
          imgClass: "states-items-img",
          slickClass: "slider-news-states"
        };
      }
    }
  },
  computed: {
    slickOptions() {
      return {
        infinite: true,
        dots: false,
        slidesToShow: this.articlesToShow,
        slidesToScroll: 1,
        rows: 0,
        prevArrow:
          "<div class='slick-prev-states'><img src='" +
          this.arrowUrl("right") +
          "' alt='' /></div>",
        nextArrow:
          "<div class='slick-next-states'><img src='" +
          this.arrowUrl("left") +
          "' alt='' /></div>",
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
    }
  },
  methods: {
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    reInit() {
      this.$nextTick(() => {
        if (this.$refs.slicks) {
          this.$refs.slicks.reSlick();
        }
      });
    }
  },
  mounted() {
    setTimeout(this.reInit, 1e3);
  }
};
</script>

<style lang="scss" scoped>
.slider-news-states,
.news-states-items {
  max-height: 370px;
}
</style>
