<template>
  <div class="table-responsive mb-4 mt-4">
    <button
      class="button new"
      v-bind:class="{ danger: sortParam === true }"
      @click="sortedList()"
    >
      <i class="fa fa-fire mr-2" aria-hidden="true"></i> Новые пользователи
    </button>
    <table id="zero-config" class="table" style="width: 100%">
      <thead>
        <tr>
          <th v-for="(head, key) in heads" v-bind:key="key">
            {{ head.name }}
          </th>
        </tr>
      </thead>
      <tbody v-if="sortParam == false">
        <tr v-for="item in items" v-bind:key="item.key">
          <td v-for="(head, key) in heads" v-bind:key="key">
            <a
              class="edit-table"
              :href="item[key]"
              v-if="head.type === 'picture'"
              target="_blank"
            >
              Открыть
            </a>
            <span v-else-if="head.type === 'datetime'">
              <b v-if="item[key]">{{ item[key] | moment("DD.MM.YY hh:mm") }}</b>
              <b v-else>Нет</b>
            </span>
            <span v-else>{{ item[key] || 0 }}</span>
          </td>
          <td class="text-center" v-if="editable">
            <ul class="table-controls">
              <li>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Edit"
                  @click.prevent="editItem(item.id)"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit-2"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    ></path></svg
                ></a>
              </li>
              <li>
                <a
                  href="#"
                  data-toggle="tooltip"
                  data-placement="top"
                  title=""
                  data-original-title="Delete"
                  @click.prevent="deleteItem(item.id)"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-trash-2"
                  >
                    <polyline points="3 6 5 6 21 6"></polyline>
                    <path
                      d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
                    ></path>
                    <line x1="10" y1="11" x2="10" y2="17"></line>
                    <line x1="14" y1="11" x2="14" y2="17"></line></svg
                ></a>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(item, index) in allform" v-bind:key="item.key">
          <td v-if="index > 14">
            {{ item[index].id }}
          </td>
          <td v-if="index > 14">
            {{ item[index].name }}
          </td>
          <td v-if="index > 14">
            {{ item[index].email }}
          </td>
          <td v-if="index > 14">
            <span v-if="item[index].country">
              <b>{{ item[index].country }}</b>
            </span>
            <span v-else>
              <b>Нет</b>
            </span>
          </td>
          <td v-if="index > 14">
            <span v-if="item[index].phone">
              <b>{{ item[index].phone }}</b>
            </span>
            <span v-else>
              <b>Нет</b>
            </span>
          </td>
          <td v-if="index > 14">
            {{ item[index].subType }}
          </td>
          <td v-if="index > 14">
            <span>
              <b v-if="item[index].lastLoginDate">{{
                item[index].lastLoginDate | moment("DD.MM.YY hh:mm")
              }}</b>
              <b v-else>Нет</b>
            </span>
          </td>
          <td v-if="index > 14">
            <span>
              <b v-if="item[index].subscriptionDate">{{
                item[index].subscriptionDate | moment("DD.MM.YY hh:mm")
              }}</b>
              <b v-else>Нет</b>
            </span>
          </td>
          <td v-if="index > 14">
            {{ item[index].articleViewCount }}
          </td>
          <td v-if="index > 14">
            {{ item[index].videoViewCount }}
          </td>
          <td v-if="index > 14">
            <span v-if="item[index].lastArticleView">
              <b>{{
                item[index].lastArticleView | moment("DD.MM.YY hh:mm")
              }}</b>
            </span>
            <span v-else>
              <b>Нет</b>
            </span>
          </td>
          <td v-if="index > 14">
            <span v-if="item[index].lastVideoView">
              <b>{{ item[index].lastVideoView | moment("DD.MM.YY hh:mm") }}</b>
            </span>
            <span v-else>
              <b>Нет</b>
            </span>
          </td>
          <td v-if="index > 14">
            <span v-if="item[index].createdDate">
              <b>{{ item[index].createdDate | moment("DD.MM.YY hh:mm") }}</b>
            </span>
            <span v-else>
              <b>Нет</b>
            </span>
          </td>
        </tr>
        <!-- 
        <section v-else>
          <tr>
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
          </tr>            
        </section>   -->
      </tbody>
    </table>
    <paginate
      :page.sync="currentPage"
      :length="items.length"
      @pageChanged="updateItem"
      v-if="usePagination"
    />
    <modal-admin
      :show-modal.sync="showModal"
      :header="editHead"
      :item-data.sync="editableData"
      :fields="fields"
      :form-options="formOptions"
      :update-method="updateMethod"
      :old-form="oldForm"
      @recordUpdated="updateItem"
      v-if="showModal && !!fields"
    />
  </div>
</template>

<script>
import ModalAdmin from "@/components/page/modals/forms/ModalAdmin";
import Paginate from "@/components/admin/lists/Paginate";
import axios from "axios";

export default {
  name: "TableViewUser",
  data() {
    return {
      showModal: false,
      editableData: {},
      usernewform: [],
      allform: [],
      sortParam: false,
      size: 30,
      end: 200
    };
  },
  mounted() {
    this.userform(1);
  },
  components: { Paginate, ModalAdmin },
  props: {
    heads: Object,
    items: Array,
    fields: {
      type: [Object, Array],
      default() {
        return null;
      }
    },
    formOptions: {
      type: Object,
      default() {
        return {};
      }
    },
    page: {
      type: Number,
      default: 1
    },
    usePagination: {
      type: Boolean,
      default: true
    },
    editHead: String,
    updateMethod: String,
    deleteMethod: String,
    obtainMethod: String,
    editable: {
      type: Boolean,
      default: true
    },
    oldForm: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(n) {
        this.$emit("update:page", n);
      }
    }
  },
  methods: {
    async userform() {
      for (let i = 1; i < this.end; i++) {
        await axios
          .get(
            `https://mediateka.pro/v1/auth/info/list?size=30&search=&page=${i}`
          )
          .then(response => {
            console.log(i);

            const object = response.data;

            console.log(object);
            this.allform.push(object);
          })
          .catch(error => {
            this.end = i;
            console.log(error);
          });
      }
    },
    sortedList() {
      if (this.sortParam == false) {
        this.sortParam = true;
        console.log(this.allform);
        this.allform.sort(function(a, b) {
          if (a.createdDate < b.createdDate) {
            return 1;
          }
          if (a.createdDate > b.createdDate) {
            return -1;
          }
          // a должно быть равным b
          return 0;
        });

        console.log(this.allform);
      }

      if (this.sortParam == true) {
        this.sortParam == false;
      }
    },
    // sortByNew() {
    //     for (let i = 0; i < this.usernewform.length; i++) {
    //       const item1 = this.usernewform[i]
    //       const item2 = this.usernewform[i]
    //       console.log(this.currentPage)
    //       return (item1.createdDate < item2.createdDate) ? 1 : -1;
    //     }
    // },
    // sortByExit() {
    //     for (let i = 0; i < this.usernewform.length; i++) {
    //       const item1 = this.usernewform[i]
    //       const item2 = this.usernewform[i]
    //       return (item1.createdDate > item2.createdDate) ? 1 : -1;
    //     }
    // },
    updateItem() {
      this.$emit("updated");
    },
    editItem(item) {
      this.$http
        .get(this.obtainMethod, {
          params: {
            id: item
          }
        })
        .then(response => {
          this.editableData = response.data;
          this.showModal = true;
        });
    },
    deleteItem(item) {
      this.$http
        .delete(this.deleteMethod, {
          params: {
            id: item
          }
        })
        .then(response => {
          console.log(response.data);
          this.updateItem();
        });
    }
  },
  watch: {
    currentPage() {
      this.userform(this.currentPage);
    }
  }
};
</script>

<style scoped>
ul li {
  display: inline;
  margin-right: 5px;
  padding: 3px;
}
.mb-4 {
  margin-bottom: 20px;
}
.mt-4 {
  margin-top: 20px;
}

.new {
  color: #dc3545 !important;
  border-color: #dc3545 !important;
}

.button {
  background-color: #fff;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);

  padding-left: 1em;

  padding-right: 1em;

  padding-top: calc(0.5em - 1px);

  text-align: center;
  white-space: nowrap;
}

.danger {
  background-color: #dc3545 !important;
  color: #fff !important;
}

.center {
  text-align: center;
  margin: auto;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
