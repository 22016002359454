<template>
  <fragment>
    <section class="podpiska-wrapper">
      <site-header />
      <div class="podpiska-header-wrapper">
        <div class="inner-content">
          <h4>
            Выберите подписку на сервис с полезной информацией от ТОП спикеров
            России и мира Только развивающий контент в лучшем качестве и без
            рекламы
          </h4>
          <p>
            Выберите подписку<br />
            Более тысячи часов развивающего видеоконтента в Full HD качестве,
            статьи по бизнес-тематике и обзор ключевых бизнес событий в мире
          </p>
          <a href="#" @click.prevent="scrollToGift">
            <!--            Попробовать 1 день за 1 рубль-->
            Подключить
          </a>
        </div>
      </div>
    </section>
    <subscription-block ref="sub_block" :tiles="subs" />
    <gift-block :certs="certs" />
    <figures-block />
    <popular-block :media="videosTest" head="Всегда есть, что посмотреть" />
    <site-footer />
  </fragment>
</template>

<script>
import SiteHeader from "@/components/page/SiteHeader";
import SiteFooter from "@/components/page/SiteFooter";
import GiftBlock from "@/components/page/certs/GiftBlock";
import FiguresBlock from "@/components/page/certs/FiguresBlock";
import PopularBlock from "@/components/page/main/PopularBlock";
import { WOW } from "wowjs";
import SubscriptionBlock from "@/components/page/certs/SubscriptionBlock";
import { mapGetters, mapActions } from "vuex";
import { smoothVerticalScroll } from "@/store/util/methods";

export default {
  name: "Subscription",
  components: {
    SubscriptionBlock,
    PopularBlock,
    FiguresBlock,
    GiftBlock,
    SiteFooter,
    SiteHeader
  },
  computed: {
    ...mapGetters(["videosTest", "certs", "subs"])
  },
  methods: {
    ...mapActions(["getPageVideos", "getCerts", "getSubs"]),
    scrollToGift() {
      const o = this.$refs.sub_block.$el;
      smoothVerticalScroll(o, 750, "top");
    }
  },
  created() {
    this.getSubs().then(() => {
      this.getCerts().then(() => {
        this.getPageVideos({
          method: "/video/list",
          type: "videosTest",
          params: {
            size: 16,
            page: 1
          }
        });
      });
    });
  },
  mounted() {
    new WOW({ offset: 140, live: false }).init();
  }
};
</script>

<style scoped></style>
