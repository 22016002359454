<template>
  <div style="display: flex;">
    <ul>
      <li v-for="(item, key, index) in links" :key="index">
        <router-link :to="'/' + key" v-html="item" @click="goTo" />
      </li>
    </ul>
    <ul class="displaynonemobile">
      <li>
        <router-link class="promocodenewlinked" to="/promocode"
          >Ввести промокод</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  data() {
    return {
      links: {
        library: "Медиатека",
        events: "События",
        articles: "Заметки",
        speakers: "Спикеры"
      }
    };
  },
  methods: {
    goTo() {
      this.$emit("navigation");
    }
  }
};
</script>

<style scoped></style>
