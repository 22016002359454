<template>
  <site-section section-class="kategory-tovar">
    <template v-slot:header>
      <h2>Категории</h2>
    </template>
    <template v-slot:content>
      <!--         <router-link
          v-for="i in categories"
          v-bind:key="i.id"
          class="wow fadeInUp"
          :to="{ name: 'MediaLibrary', params: { category: i.id } }"
        >
          <span>{{ i.title }}</span>
        </router-link> -->
      <categoriesitems
        :categories="categories"
        v-if="categories && categories.length"
      />
      <div class="clear"></div>
    </template>
  </site-section>
</template>

<script>
import Categoriesitems from "@/components/page/slick/Categoriesitems";
import SiteSection from "@/components/page/SiteSection";
export default {
  name: "CategoriesBlock",
  components: { SiteSection, Categoriesitems },
  props: {
    categories: Array
  }
};
</script>

<style scoped></style>
