import { render, staticRenderFns } from "./ModalAlert.vue?vue&type=template&id=97e3015c&scoped=true&"
import script from "./ModalAlert.vue?vue&type=script&lang=js&"
export * from "./ModalAlert.vue?vue&type=script&lang=js&"
import style0 from "./ModalAlert.vue?vue&type=style&index=0&id=97e3015c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97e3015c",
  null
  
)

export default component.exports