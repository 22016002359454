<template>
  <state-item item-class="b-kategory">
    <router-link
      :class="'wow' + ' ' + 'fadeInUp' + ' ' + 'newkategoryab' + data.id"
      :to="{ name: 'MediaLibrary', params: { category: data.id } }"
    >
      <span>{{ data.title }}</span>
    </router-link>
  </state-item>
</template>

<script>
import StateItem from "@/components/page/slick/StateItem";
// import List from "@/components/page/items/list/List";
export default {
  name: "SubItem",
  components: { StateItem },
  props: {
    data: Object
  }
};
</script>

<style scoped></style>
