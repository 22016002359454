<template>
  <footer class="footer">
    <div class="inner-content">
      <div class="flex-top-footer">
        <div class="top-footer-left displaynonemobile">
          <top-footer-menu />
        </div>
        <div class="newblock">
          <router-link to="/library">Медиатека</router-link>
          <router-link to="/events">События</router-link>
          <br />
          <router-link to="/articles">Заметки</router-link>
          <router-link to="/speakers">Спикеры</router-link>
          <br />
          <router-link style="color: #EA4C89 !important;" to="/sub"
            >Выбрать подписку</router-link
          >
        </div>
        <div class="top-footer-right">
          <router-link to="/promocode" class="promoacepted">
            Ввести промокод
          </router-link>
        </div>
      </div>
      <div class="flex-bottom-footer" v-if="!$route.meta.hideBottomFooter">
        <div class="newblock" style="margin-bottom: 40px;">
          <router-link to="/certs">Сертификаты</router-link>
          <router-link to="/partnership">Сотрудничество</router-link>
          <br />
          <router-link to="/faq">Помощь</router-link>
        </div>
        <div class="displayreadymobile">
          <div class="newblockforsoz">
            <a href="https://vk.com/public209656985" target="_blank">
              <i class="fab fa-vk"></i>
            </a>
            <a href="https://www.instagram.com/mediateka.pro/" target="_blank">
              <i class="fab fa-instagram"></i>
            </a>
            <a
              href="https://www.facebook.com/MEDIATEKAPRO-109950274883168/?ref=page_internal"
              target="_blank"
            >
              <i class="fab fa-facebook-f"></i>
            </a>
            <a href="https://ok.ru/group/62161233707130" target="_blank">
              <i class="fab fa-odnoklassniki"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCkeqfsRDlbZJ58DnpLzXlTA"
              target="_blank"
            >
              <i class="fab fa-youtube"></i>
            </a>
            <a href="https://t.me/mediateka_pro" target="_blank">
              <i class="fab fa-telegram"></i>
            </a>
          </div>
        </div>
        <div class="bottom-footer-left">
          <div class="displaynonemobile">
            <bottom-footer-menu />
          </div>

          <div class="clear"></div>

          <div class="displaynonemobile">
            <ul
              class="bottom-footer-copy"
              style="display: -webkit-inline-box !important;"
            >
              <li><a>© 2020-2022 mediateka.pro</a></li>
              <li>
                <a href="/useragreement_mediateka" target="_blank">
                  Пользовательское соглашение
                </a>
              </li>
              <li>
                <a href="/privacypolicy_mediateka" target="_blank">
                  Политика конфиденциальности
                </a>
              </li>
              <li>
                <a href="mailto:hello@mediateka.pro">hello@mediateka.pro</a>
              </li>
            </ul>
          </div>
          <div class="displayreadymobile">
            <div style="display: inline-grid !important;">
              <a class="lastfootertext">© 2020-2022 mediateka.pro</a>
              <a
                class="lastfootertext"
                href="/useragreement_mediateka"
                target="_blank"
              >
                Пользовательское соглашение
              </a>
              <a
                class="lastfootertext"
                href="/privacypolicy_mediateka"
                target="_blank"
              >
                Политика конфиденциальности
              </a>
              <a class="lastfootertext" href="mailto:hello@mediateka.pro"
                >hello@mediateka.pro</a
              >
            </div>
          </div>
        </div>
        <div class="bottom-footer-right displaynonemobile">
          <a href="https://vk.com/public209656985" target="_blank">
            <i class="fab fa-vk"></i>
          </a>
          <a href="https://www.instagram.com/mediateka.pro/" target="_blank">
            <i class="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.facebook.com/MEDIATEKAPRO-109950274883168/?ref=page_internal"
            target="_blank"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <a href="https://ok.ru/group/62161233707130" target="_blank">
            <i class="fab fa-odnoklassniki"></i>
          </a>
          <a
            href="https://www.youtube.com/channel/UCkeqfsRDlbZJ58DnpLzXlTA"
            target="_blank"
          >
            <i class="fab fa-youtube"></i>
          </a>
          <a href="https://t.me/mediateka_pro" target="_blank">
            <i class="fab fa-telegram"></i>
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import TopFooterMenu from "@/components/page/menu/TopFooterMenu";
import BottomFooterMenu from "@/components/page/menu/BottomFooterMenu";
export default {
  name: "SiteFooter",
  components: { BottomFooterMenu, TopFooterMenu }
};
</script>

<style scoped>
.displaynonemobile {
  display: flex !important;
}

@media (max-width: 768px) {
  .displaynonemobile {
    display: none !important;
  }
}
</style>
