<template>
  <ul>
    <li><router-link to="/library">Медиатека</router-link></li>
    <li><router-link to="/events">События</router-link></li>
    <li><router-link to="/articles">Заметки</router-link></li>
    <li><router-link to="/speakers">Спикеры</router-link></li>
    <li><router-link to="/sub">Выбрать подписку</router-link></li>
  </ul>
</template>

<script>
export default {
  name: "TopFooterMenu"
};
</script>

<style scoped></style>
