<template>
  <state-item item-class="news-tovar-items-inner">
    <router-link
      :style="{
        background: 'url(' + item.coverUrl + ')'
      }"
      :to="{ name: 'SingleEvent', params: { id: item.id } }"
      class="event-videosnewbord video-img"
    >
      <!--     <tags :tags="item.categories" /> -->
      <div class="textinvideotop">
        <div class="tovar-items-status" v-if="item.startDate || item.endDate">
          <div class="statuscolendarnew">
            <span class="items-status-date">
              <i class="far fa-calendar"></i>
            </span>
            <span class="items-status-text">
              {{ item.startDate | moment("DD.MM.YY hh:mm") }}
            </span>
          </div>
          <div class="statuscolendarnew" v-if="item.endDate">
            <span class="items-status-date">
              <i class="far fa-calendar"></i>
            </span>
            <span class="items-status-text">
              {{ item.endDate | moment("DD.MM.YY hh:mm") }}
            </span>
          </div>
        </div>
      </div>
      <div class="textinvideo">
        <h4>
          <router-link :to="{ name: 'SingleEvent', params: { id: item.id } }">
            {{ item.title }}
          </router-link>
        </h4>
        <span
          class="nti-d"
          v-html="item.description"
          v-if="item.description"
        ></span>
      </div>
    </router-link>
  </state-item>
</template>

<script>
// import Tags from "@/components/page/items/tags/Tags";
import StateItem from "@/components/page/slick/StateItem";
export default {
  name: "EventItem",
  components: { StateItem },
  props: {
    item: Object
  }
};
</script>

<style scoped></style>
