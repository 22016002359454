<template>
  <slick ref="slick" :options="slickOptions" class="slider-news-meropriyatiya">
    <sub-item v-for="cat in categories" v-bind:key="cat.key" :data="cat" />
  </slick>
</template>

<script>
import Slick from "vue-slick";
import SubItem from "@/components/page/categories/SubItem";
export default {
  name: "Categoriesitems",
  components: { SubItem, Slick },
  props: {
    categories: Array
  },
  data() {
    return {
      slickOptions: {
        infinite: true,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        rows: 0,
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style scoped></style>
