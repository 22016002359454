<template>
  <site-section section-class="news-tovar">
    <template v-slot:header>
      <h2>Тарифы</h2>
      <router-link to="/sub">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13.503"
          height="23.619"
          viewBox="0 0 13.503 23.619"
        >
          <path
            id="Icon_ionic-ios-arrow-back"
            data-name="Icon ionic-ios-arrow-back"
            d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z"
            transform="translate(24.754 29.813) rotate(180)"
          />
        </svg>
      </router-link>
    </template>
    <!--     <template v-slot:description>
      <p class="pre-flex-header wow fadeInUp">
        Более тысячи часов развивающего видеоконтента в Full HD качестве
        <br />и статьи по бизнес-тематике по стоимости дешевле, чем посещение
        одного мероприятия.
      </p>
    </template> -->
    <template v-slot:content>
      <subscription :tiles="tiles" v-if="tiles && tiles.length" />
    </template>
  </site-section>
</template>

<script>
import Subscription from "@/components/page/slick/Subscription";
import SiteSection from "@/components/page/SiteSection";

export default {
  name: "SubscriptionBlock",
  components: { Subscription, SiteSection },
  props: {
    tiles: Array
  }
  // data() {
  //   return {
  //     tiles: [
  //       {
  //         id: 0,
  //         type: "1 день",
  //         price: 1,
  //         pre: "Более 1000+ видео контента",
  //         next: "Далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 1,
  //         type: "1 месяц",
  //         price: 990,
  //         pre: "Более 1000+ видео контента",
  //         next: "1 день за 1 ₽ / далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 2,
  //         type: "1 год",
  //         price: 5990,
  //         pre: "Более 1000+ видео контента",
  //         next: "Месяц за 430 ₽ / – 50%",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 3,
  //         type: "1 день",
  //         price: 1,
  //         pre: "Более 1000+ видео контента",
  //         next: "Далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       },
  //       {
  //         id: 4,
  //         type: "1 день",
  //         price: 1,
  //         pre: "Более 1000+ видео контента",
  //         next: "Далее 990 ₽ в месяц",
  //         list: [
  //           "Статьи и видео по бизнес тематике",
  //           "Лучшее качество записи",
  //           "Просмотр на любом устройстве",
  //           "Отличный подарок"
  //         ],
  //         cover: 1
  //       }
  //     ]
  //   };
  // }
};
</script>

<style scoped></style>
