<template>
  <modal-skeleton2
    :class-list="classList"
    v-if="showModal"
    @close="$emit('update:showModal', false)"
  >
    <template v-slot:body>
      <h3>{{ alertText }}</h3>
    </template>
    <template v-slot:footer>
      <button class="modal-action" @click="actionModal">
        {{ actionText }}
      </button>
    </template>
  </modal-skeleton2>
</template>

<script>
import ModalSkeleton2 from "@/components/page/modals/ModalSkeleton2";
export default {
  name: "ModalAlert",
  components: { ModalSkeleton2 },
  props: {
    showModal: Boolean,
    actionModal: Function,
    alertText: String,
    actionText: String,
    classList: {
      type: [Array, Object, String],
      default: ""
    }
  },
  computed: {
    modalVisible: {
      get() {
        return this.showModal;
      },
      set(n) {
        this.$emit("update:showModal", n);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-body {
  color: #fff !important;
}
.modal-action {
  display: block;
  cursor: pointer;
  background: #ea4c89;
  border-radius: 4px;
  border: none;
  text-align: center;
  padding: 23px 0;
  margin-top: 10px;
  width: 240px;
  letter-spacing: 0.18px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  transition: all 0.5s ease;
  &:hover {
    background: #d4467c;
    transition: all 0.5s ease;
  }
}
</style>
