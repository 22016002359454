<template>
  <div>
    <div class="wrapper-inner">
      <div class="inner-content">
        <div class="about-wrapper-detail">
          <h1 class="header-big">Оплата услуг</h1>
        </div>
      </div>
    </div>
    <payment-block
      :good.sync="good"
      :payment="payment"
      :promo.sync="promo"
      :promoPaynew="promoPaynew"
      :password="password"
      :cert-data="{ addName, addComment, addType }"
      @goodUpdated="goodUpdate"
    />
  </div>
</template>

<script>
import PaymentBlock from "@/components/page/pay/PaymentBlock";
export default {
  name: "Pay",
  components: { PaymentBlock },
  props: {
    payment: {
      type: String
    },
    goodId: {
      type: [String, Number]
    },
    password: {
      type: [String, Number],
      default: null
    },
    promo: {
      type: [Object, Array, String],
      default: null
    },
    promoPaynew: {
      type: Number,
      default: 0
    },
    addName: {
      type: String,
      default: ""
    },
    addComment: {
      type: String,
      default: ""
    },
    addType: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      good: {},
      newprice: 1,
      dromoId: "",
      pricedefoult: ""
    };
  },
  methods: {
    getSubscription(promo) {
      return this.axios
        .get("/subscription/get", { params: { id: this.goodId } })
        .then(response => {
          const data = response.data;
          if (this.promoPaynew == 1) {
            if (promo) {
              data.old_price = data.price;
              this.pricedefoult = data.price;
              this.$http.get(`/promo/check?promo=${promo}`).then(response => {
                this.dromoId = response.data.id;
              });
              data.price = this.newprice;
              console.log(data.price);
              data.promoId = this.dromoId;
            }
          } else {
            if (promo) {
              data.old_price = data.price;
              data.price = (parseInt(data.price) * (100 - promo.percent)) / 100;
              data.promoId = promo.id;
            }
          }
          this.good = {
            name: "подписку",
            ...data
          };
        });
    },
    getCertificate(promo) {
      return this.axios
        .get("/certificate/get", { params: { id: this.goodId } })
        .then(response => {
          const data = response.data;
          if (promo) {
            data.old_price = data.price;
            data.price = (parseInt(data.price) * (100 - promo.percent)) / 100;
            data.promoId = promo.id;
          }
          this.good = data;
        });
    },
    getGood(promo) {
      switch (this.payment) {
        case "sub":
          return this.getSubscription(promo);
        case "cert":
          return this.getCertificate(promo);
        default:
          this.$router.push("/");
      }
    },
    goodUpdate() {
      this.getGood(this.promo).then(() => {
        this.$forceUpdate();
      });
    }
  },
  watch: {
    goodId(n, o) {
      if (n !== o) {
        this.goodUpdate();
      }
    },
    payment(n, o) {
      if (n !== o) {
        this.goodUpdate();
      }
    }
  },
  created() {
    this.goodUpdate();
  }
};
</script>

<style scoped></style>
