import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

import Login from "@/views/user/auth/login/Login";
import SignUp from "@/views/user/auth/signup/SignUp";
import Index from "@/views/user/main/Index";
import Events from "@/views/user/events/Events";
import Event from "@/views/user/events/Event";
import Main from "@/views/user/main/Main";
import Certificates from "@/views/user/certificates/Certificates";
import Subscription from "@/views/user/subscription/Subscription";
import Promo from "@/views/user/promo/Promo";
import Partnership from "@/views/user/social/Partnership";
import Sign from "@/views/user/auth/Sign";
import RecoverEmail from "@/views/user/auth/login/RecoverEmail";
import RecoverPassword from "@/views/user/auth/login/RecoverPassword";
import VideoIndex from "@/views/user/video/VideoIndex";
import CertPay from "@/views/user/certificates/CertPay";
import Pay from "@/views/user/payment/Pay";
import Promocode from "@/views/user/promo/Promocode";
import PayConfirm from "@/views/user/payment/PayConfirm";
import Admin from "@/views/admin/Admin";
import AdminVideo from "@/views/admin/video/AdminVideo";
import AdminArticles from "@/views/admin/articles/AdminArticles";
import AdminEvents from "@/views/admin/events/AdminEvents";
import AdminCerts from "@/views/admin/certs/AdminCerts";
import AdminPromo from "@/views/admin/promo/AdminPromo";
import AdminSpeakers from "@/views/admin/speakers/AdminSpeakers";
import AdminUsers from "@/views/admin/users/AdminUsers";
import CertView from "@/views/user/certificates/CertView";
import AdminCategories from "@/views/admin/categories/AdminCategories";
import Unsubscription from "@/views/user/subscription/Unsubscription";

const routes = [
  {
    name: "Sign",
    component: Sign,
    path: "/sign",
    children: [
      {
        path: "in",
        name: "login",
        component: Login,
        props: route => ({ ...route.query, ...route.params }),
        meta: {
          auth: false
        }
      },
      {
        path: "recover/email",
        name: "RecoverEmail",
        component: RecoverEmail,
        meta: {
          auth: false,
          backTo: "login"
        }
      },
      {
        path: "recover/password",
        name: "RecoverPassword",
        component: RecoverPassword,
        props: route => ({ ...route.query, ...route.params }),
        meta: {
          backTo: "login"
        }
      },
      {
        path: "up",
        name: "signup",
        component: SignUp
      }
    ]
  },
  {
    path: "/certs",
    component: Certificates,
    meta: {
      hideBottomFooter: true,
      customClass: "body-color-blue",
      noHeaderSearch: true
    }
  },
  {
    path: "/sub",
    component: Subscription,
    name: "Subscriptions",
    meta: {
      hideBottomFooter: true,
      noHeaderSearch: true
    }
  },
  {
    path: "/unsub",
    component: Unsubscription,
    name: "Unsubscription",
    meta: {
      hideBottomFooter: true
    },
    props: route => ({ ...route.query, ...route.params })
  },
  {
    path: "/partnership",
    component: Partnership,
    meta: {
      hideBottomFooter: true,
      noHeaderSearch: true
    }
  },
  {
    path: "/video",
    component: VideoIndex,
    children: [
      {
        path: ":id",
        component: () => import("@/views/user/video/Video"),
        name: "VideoById"
      }
    ]
  },
  {
    path: "/promocode",
    component: Promocode
  },
  {
    path: "/admin",
    component: Admin,
    meta: {
      auth: true,
      backTo: "login"
      // auth: {
      //   roles: "admin",
      //   email: "hello@mediateka.pro",
      //   redirect: "/sign/in"
      // }
    },
    children: [
      {
        path: "videos",
        component: AdminVideo,
        name: "AdminVideo"
      },
      {
        path: "articles",
        name: "AdminArticles",
        component: AdminArticles
      },
      {
        path: "events",
        name: "AdminEvents",
        component: AdminEvents
      },
      {
        path: "certs",
        name: "AdminCerts",
        component: AdminCerts
      },
      {
        path: "promo",
        name: "AdminPromo",
        component: AdminPromo
      },
      {
        path: "speakers",
        name: "AdminSpeakers",
        component: AdminSpeakers
      },
      {
        path: "categories",
        name: "AdminCategories",
        component: AdminCategories
      },
      {
        path: "users",
        name: "AdminUsers",
        component: AdminUsers
      }
    ]
  },
  {
    path: "/",
    component: Index,
    children: [
      {
        path: "/pay",
        component: Pay,
        name: "Payment",
        props: route => ({ ...route.query, ...route.params }),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/pay/confirm/:payType",
        component: PayConfirm,
        name: "PaymentConfirm",
        props: route => ({ ...route.query, ...route.params }),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/cert/view",
        component: CertView,
        name: "CertView",
        props: route => ({ ...route.query, ...route.params }),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/certs/pay/:id?",
        component: CertPay,
        name: "CertPay",
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/events",
        component: Events,
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/event/:id",
        component: Event,
        name: "SingleEvent",
        props: route => ({ ...route.query, ...route.params })
      },
      {
        path: "/library/:category?",
        name: "MediaLibrary",
        component: () => import("@/views/user/media/MediaLibrary")
      },
      {
        path: "/articles",
        component: () => import("@/views/user/article/Articles")
      },
      {
        path: "/article/:id",
        name: "SingleArticle",
        component: () => import("@/views/user/article/Article"),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/promo",
        component: Promo
        // meta: {
        //   noWrapper: true,
        //   auth: true
        // }
      },
      {
        path: "/novelty",
        component: () => import("@/views/user/media/Novelty")
      },
      {
        path: "/services",
        component: () => import("@/views/user/service/Services"),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/service/:id",
        name: "SingleService",
        component: () => import("@/views/user/service/Service"),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/speaker/:id",
        name: "SingleSpeaker",
        component: () => import("@/views/user/speaker/Speaker"),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/faq",
        component: () => import("@/views/user/social/FAQ"),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/profile",
        component: () => import("@/views/user/profile/Profile"),
        name: "UserProfile",
        meta: {
          noWrapper: true,
          auth: true
        }
      },
      {
        path: "/profile/viewed",
        component: () => import("@/views/user/profile/ViewedVideos"),
        meta: {
          noWrapper: true,
          auth: true
        }
      },
      {
        path: "/profile/favourite",
        component: () => import("@/views/user/profile/WatchLater"),
        meta: {
          noWrapper: true,
          auth: true
        }
      },
      {
        path: "/profile/data",
        component: () => import("@/views/user/profile/ProfileData"),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/profile/notification",
        component: () => import("@/views/user/profile/Notification"),
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/search/:data?",
        component: () => import("@/views/user/profile/SearchResult"),
        name: "SearchResult",
        props: route => ({ ...route.query, ...route.params }),
        meta: {
          noWrapper: true,
          noHeaderSearch: true
        }
      },
      {
        path: "/speakers",
        component: () => import("@/views/user/speaker/Speakers"),
        name: "Speakers",
        meta: {
          noWrapper: true
        }
      },
      {
        path: "/",
        name: "Parent",
        component: Main
      }
    ]
  }
];

const router = new VueRouter({
  history: true,
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  if (from.meta.customClass) {
    document.body.classList.remove(from.meta.customClass);
  }
  if (to.meta.customClass) {
    document.body.classList.add(to.meta.customClass);
  }

  next();
});

export default router;
