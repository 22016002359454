<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-sm-12 w100 layout-spacing">
      <div class="widget-content widget-content-area">
        <h2>Зарегистрированные пользователи</h2>
        <table-view-user
          :heads="heads"
          :items.sync="users"
          :page.sync="page"
          :editable="false"
          @updated="loadUsers"
        />
      </div>
      <div class="widget-content widget-content-area">
        <h2>Сделать массовую рассылку</h2>
        <send-users :schema="schema" :form-options="formOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TableViewUser from "@/components/admin/lists/TableViewUser";
import SendUsers from "@/views/admin/users/SendUsers";
import { uuid } from "vue-uuid";
import { validators } from "vue-form-generator";
export default {
  name: "AdminUsers",
  data() {
    return {
      heads: {
        id: {
          name: "ID"
        },
        name: {
          name: "ФИО"
        },
        email: {
          name: "E-mail"
        },
        country: {
          name: "Страна"
        },
        phone: {
          name: "Телефон"
        },
        subType: {
          name: "Подписка"
        },
        lastLoginDate: {
          name: "Дата входа",
          type: "datetime"
        },
        subscriptionDate: {
          name: "Дата подписки",
          type: "datetime"
        },
        articleViewCount: {
          name: "Просмотренные статьи"
        },
        videoViewCount: {
          name: "Просмотренные видео"
        },
        lastArticleView: {
          name: "Последний просмотр статьи",
          type: "datetime"
        },
        lastVideoView: {
          name: "Последний просмотр видео",
          type: "datetime"
        },
        createdDate: {
          name: "Аккаунт создан",
          type: "datetime"
        }
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true
      },
      emailTimeout: null,
      page: 1
    };
  },
  computed: {
    ...mapGetters(["users", "usersForList"]),
    schema() {
      return {
        groups: [
          {
            legend: "Основное",
            fields: [
              {
                type: "input",
                inputType: "text",
                label: "Тема письма",
                model: "subject",
                required: true,
                validator: validators.required,
                placeholder: "Новые мероприятия",
                id: uuid.v1() + "subject"
              },
              {
                type: "textArea",
                label: "Тело письма",
                model: "htmlBody",
                required: true,
                hint: "Можно использовать HTML-теги",
                placeholder: "<h1>Отличная новость</h1>",
                rows: 10,
                validator: validators.string
              },
              {
                type: "switch",
                label: "Подписанные на новости",
                model: "news",
                textOn: "Разошлем",
                textOff: "Не разошлем",
                hint: "Опционально",
                disabled(model) {
                  return model && model.all;
                }
              },
              {
                type: "switch",
                label: "Подписанные на рекомендованный контент",
                model: "recommendation",
                textOn: "Разошлем",
                textOff: "Не разошлем",
                hint: "Опционально",
                disabled(model) {
                  return model && model.all;
                }
              },
              {
                type: "switch",
                label: "Рассылка для всех",
                model: "all",
                textOn: "Разошлем",
                hint: "Рассылка для всех блокирует остальные варианты отправки",
                textOff: "Не разошлем"
              },
              {
                type: "vueMultiSelect",
                model: "to",
                label: "Email для рассылки",
                placeholder: "Введите Email",
                hint:
                  "Возможна отправка или только на выбранные Email, или в дополнение к рассылке " +
                  "подписчикам на контент и новости." +
                  "<br>Обязательно, если не выбраны другие варианты отправки." +
                  "<br>Можно выбрать как уже зарегистрированный Email, так и сторонний",
                required(model) {
                  return (
                    model && !model.all && !model.recommendation && !model.news
                  );
                },
                disabled(model) {
                  return model && model.all;
                },
                selectOptions: {
                  multiple: true,
                  searchable: true,
                  internalSearch: false,
                  clearOnSelect: false,
                  closeOnSelect: false,
                  taggable: true,
                  tagPlaceholder: "Добавьте Email",
                  onNewTag(newTag, id, options, value) {
                    options.push(newTag);
                    value.push(newTag);
                  },
                  // eslint-disable-next-line no-unused-vars
                  onSearch: (searchQuery, id, options) => {
                    if (searchQuery.length === 0) {
                      options = [];
                    } else {
                      if (this.emailTimeout) {
                        clearTimeout(this.emailTimeout);
                      }
                      this.emailTimeout = setTimeout(() => {
                        this.loadFilteredUsers(
                          "usersForList",
                          searchQuery,
                          1,
                          30
                        ).then(data => {
                          options = data;
                        });
                      }, 1e3);
                    }
                  }
                },
                onChanged(model, newVal) {
                  model.to = newVal;
                },
                values: () => {
                  return this.usersForList;
                }
              }
            ]
          }
        ]
      };
    }
  },
  props: {
    searchParams: {
      type: [String, Object],
      default: ""
    }
  },
  components: { SendUsers, TableViewUser },
  methods: {
    ...mapActions(["getFilteredUsers"]),
    loadFilteredUsers(type, search, page, size) {
      return this.getFilteredUsers({
        type,
        size,
        search,
        page
      });
    },
    loadUsers() {
      this.loadFilteredUsers("users", this.searchParams, this.page, 30);
    }
  },
  watch: {
    searchParams(n, o) {
      if (n !== o) {
        this.loadUsers();
      }
    }
  },
  created() {
    this.loadUsers();
    this.loadFilteredUsers("usersForList", "", 1, 30);
  }
};
</script>

<style scoped></style>
