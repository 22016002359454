<template>
  <div id="reg-window1">
    <h2>Войти</h2>
    <p class="reg-pre">
      Войдите или зарегистрируйтесь для доступа к контенту
    </p>
    <validation-observer ref="observer" v-slot="{ passes }">
      <form @submit.prevent="passes(auth)">
        <validation-provider
          name="E-mail"
          mode="passive"
          rules="required|email"
          tag="div"
          class="input-reg-area"
          v-slot="{ errors, reset }"
        >
          <input
            v-model.trim="formData.email"
            type="email"
            placeholder="E-mail"
            :class="errors.length ? 'error-input' : ''"
            @click="reset"
          />
          <error-pop-over :errors="errors" :reset-function="reset" />
        </validation-provider>
        <validation-provider
          name="Пароль"
          vid="password"
          mode="aggressive"
          tag="div"
          class="input-reg-area"
          v-slot="{ errors, reset }"
        >
          <input
            v-model="formData.password"
            :type="togglePassword ? 'text' : 'password'"
            placeholder="Пароль"
            :class="errors.length ? 'error-input' : ''"
            @click="reset"
          />
          <a href="#" @click.prevent="togglePassword = !togglePassword">
            <i class="fas fa-eye"></i>
          </a>
          <error-pop-over :reset-function="reset" :errors="errors" />
        </validation-provider>
        <router-link class="remove-pass" to="/sign/recover/email">
          Забыли пароль?
        </router-link>
        <div class="input-reg-submit">
          <input type="submit" value="Войти" />
        </div>
      </form>
    </validation-observer>
    <p class="login-reg">
      Нет аккаунта?
      <router-link to="/sign/up">Зарегистрируйтесь</router-link>
    </p>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { messages } from "vee-validate/dist/locale/ru.json";
import { email, required } from "vee-validate/dist/rules";
import ErrorPopOver from "@/components/page/items/error/ErrorPopOver";
extend("required", {
  ...required,
  message: messages["required"]
});
extend("email", {
  ...email,
  message:
    "В поле E-mail необходимо ввести электронный адрес действительного почтового ящика"
});
export default {
  name: "Login",
  components: { ErrorPopOver, ValidationProvider, ValidationObserver },
  data() {
    return {
      togglePassword: false,
      formData: {
        email: null,
        password: null
      }
    };
  },
  props: {
    redirect: {
      type: String,
      default: null
    }
  },
  methods: {
    auth() {
      this.$auth
        .login({
          data: this.formData,
          fetchUser: true,
          // redirect: this.redirect,
          rememberMe: true
        })
        // .then(() => {
        //   if (this.redirect) {
        //     this.$router.push(this.redirect);
        //   } else {
        //     const redirect = this.$auth.redirect();

        //     const redirectTo = redirect
        //       ? redirect.from.name
        //       : this.$auth.user().roles === "admin"
        //       ? "AdminVideo"
        //       : "UserProfile";

        //     this.$router.push({ name: redirectTo });
        //   }
        // })
        .catch(err => {
          switch (err.response.data.error) {
            case "Unauthorized":
            case "object_not_found_error":
              this.$refs.observer.setErrors({
                password: "Неверный логин или пароль"
              });
              break;
          }
        });
    }
  }
};
</script>

<style></style>
