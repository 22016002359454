<template>
  <header :class="{ 'header-fixed': burgerVisible }">
    <div class="top-left">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/img/logoheader.svg" alt="Logo" />
        </router-link>
        <br />
        <br />
        <br />
      </div>
      <transition name="fade">
        <div
          style="margin-left: auto;"
          class="top-menu"
          v-if="searchInputToggle.topMenuShow"
        >
          <main-menu @navigation="burgerToggle" />
        </div>
      </transition>
    </div>
    <div class="top-right">
      <div
        :class="{ 'top-search': true, 'right-search': !searchInputToggle.self }"
        v-if="!$route.meta.noHeaderSearch"
      >
        <span>
          <transition name="fade"></transition>
          <a href="#" @click.prevent="searchToggle">
            <img
              class="search-img search-open"
              src="@/assets/img/Iconly-Bold-Search.svg"
              alt=""
            />
          </a>
          <transition name="fade">
            <input
              v-if="searchInputToggle.self"
              class="search-input"
              @keyup="searchInput"
              ref="searchItem"
              type="text"
              placeholder="Введите название видео или спикера  "
            />
          </transition>
          <transition name="fade">
            <a
              v-if="searchInputToggle.closeSearchShow"
              class="close-search search-open"
              @click.prevent="searchToggle"
              href="#"
            >
              ✕
            </a>
          </transition>
        </span>
        <transition name="fade">
          <search-items
            :search-word="showSearch"
            :data="videos"
            v-if="showSearch && videos.length"
          />
        </transition>
      </div>
      <div class="top-login">
        <router-link to="/profile" v-if="isAuth()">
          <img src="@/assets/img/Iconly-Bold-2-User.svg" alt="" />
        </router-link>
        <router-link to="/sign/in" v-else>
          <img src="@/assets/img/Iconly-Bold-2-User.svg" alt="" />
        </router-link>
      </div>
      <div
        class="burger-mobole"
        @click.prevent="burgerToggle"
        v-if="burgerVisible"
      >
        <i class="fas fa-times"></i>
      </div>
      <div class="burger-mobole" @click.prevent="burgerToggle" v-else>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div
        :style="{
          'overflow-y': burgerVisible ? 'hidden' : 'auto',
          background: burgerVisible ? '#27292C' : '#303339'
        }"
        class="mobile-menu mobile"
        v-if="burgerVisible"
      >
        <router-link v-if="!isAuth" to="/sign/in" class="mobile-login">
          Войти
        </router-link>
        <main-menu />
        <router-link class="mobile-promo" to="/promocode">Промокод</router-link>
        <router-link class="mobile-sub" to="/sub">
          Подключить подписку
        </router-link>
        <social-menu />
      </div>
      <transition name="fade"> </transition>
      <!--      <div style="display: none" class="mobile-menu mobile">-->
      <!--        <router-link to="/sign/in" class="mobile-login">Войти</router-link>-->
      <!--        <main-menu />-->
      <!--        <router-link class="mobile-promo" to="/promo">Промокод</router-link>-->
      <!--        <social-menu />-->
      <!--      </div>-->
      <div class="top-subscreebe">
        <router-link to="/sub">Попробовать бесплатно</router-link>
      </div>
    </div>
  </header>
</template>

<script>
// todo remove the shit here;
import MainMenu from "@/components/page/menu/MainMenu";
import SocialMenu from "@/components/page/menu/SocialMenu";
import SearchItems from "@/components/page/search/items/SearchItems";
export default {
  name: "SiteHeader",
  components: { SocialMenu, MainMenu, SearchItems },
  data() {
    return {
      showSearch: false,
      searchInputToggle: {
        topMenuShow: true,
        self: false,
        closeSearchShow: false
      },
      videos: [],
      burgerVisible: false,
      timer: null
    };
  },
  methods: {
    isAuth() {
      return this.$auth.check();
    },
    burgerToggle() {
      this.burgerVisible = !this.burgerVisible;
    },
    searchToggle() {
      if (this.searchInputToggle.self) {
        this.searchInputToggle.closeSearchShow = false;
        this.searchInputToggle.topMenuShow = true;
        this.showSearch = false;
        setTimeout(() => {
          this.searchInputToggle.self = false;
        }, 300);
        // right 330px top-search
      } else {
        this.searchInputToggle.topMenuShow = false;
        this.searchInputToggle.self = true;
        setTimeout(() => {
          this.$refs.searchItem.focus();
        }, 500);
        this.searchInputToggle.closeSearchShow = true;
        // right 400px top-search
      }
    },
    searchInput(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.showSearch = e.target.value;
      if (this.showSearch) {
        this.timer = setTimeout(() => {
          this.searchVideo(this.showSearch);
        }, 800);
      }
    },
    searchVideo(s) {
      // this.videos = [];
      this.axios.get("/video/search?search=" + s).then(response => {
        this.videos = response.data.slice(0, 3);
      });
    }
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
