<template>
  <div>
    <main-banner-block v-if="false" />
    <slide-show-block :data="videosSlider" />
    <novelty-block :media="noveltyVideos" />
    <articles-block :data="articles" />
    <!--     <popular-block :media="popularVideos" /> -->
    <stream-block :media="noveltyVideos" />
    <categories-block :categories="categories" />

    <main-banners-block />
    <module-learning-block v-if="false" />
    <event-block :data="events" />
    <subscription-block :tiles="subs" />
    <!--     <speaker-block :data="speakers" /> -->
    <partner-block v-if="false" />
  </div>
</template>

<script>
import PartnerBlock from "@/components/page/main/PartnerBlock";
// import SpeakerBlock from "@/components/page/main/SpeakerBlock";
import SubscriptionBlock from "@/components/page/main/SubscriptionBlock";
import EventBlock from "@/components/page/main/EventBlock";
import ModuleLearningBlock from "@/components/page/main/ModuleLearningBlock";
import ArticlesBlock from "@/components/page/main/ArticlesBlock";
import NoveltyBlock from "@/components/page/main/NoveltyBlock";

import StreamBlock from "@/components/page/main/StreamBlock";

import SlideShowBlock from "@/components/page/main/SlideShowBlock";
import CategoriesBlock from "@/components/page/main/CategoriesBlock";
import MainBannersBlock from "@/components/page/main/MainBannersBlock";
import MainBannerBlock from "@/components/page/main/MainBannerBlock";
import { WOW } from "wowjs";
import { mapGetters, mapActions } from "vuex";
// import PopularBlock from "@/components/page/main/PopularBlock";
export default {
  name: "Main",
  components: {
    // PopularBlock,
    MainBannerBlock,
    MainBannersBlock,
    CategoriesBlock,
    SlideShowBlock,
    NoveltyBlock,
    ArticlesBlock,
    ModuleLearningBlock,
    EventBlock,
    SubscriptionBlock,
    // SpeakerBlock,
    PartnerBlock,
    StreamBlock
  },
  computed: {
    ...mapGetters([
      "categories",
      "articles",
      "popularVideos",
      "noveltyVideos",
      "speakers",
      "videosSlider",
      "events",
      "subs"
    ])
  },
  methods: {
    ...mapActions([
      "getCategories",
      "getArticles",
      "getSpeakers",
      "getNoveltyVideos",
      "getPopularVideos",
      "getSliderVideos",
      "getSubs",
      "getEvents"
    ])
  },
  created() {
    this.getSliderVideos();
    this.getNoveltyVideos({
      size: 10
    });
    this.getPopularVideos({
      size: 10
    });
    this.getSubs();
    this.getCategories();
    this.getEvents();
    this.getArticles();
    this.getSpeakers(1);
  },
  mounted() {
    new WOW({ offset: 140, live: false }).init();
  }
};
</script>

<style scoped>
.wow {
  visibility: hidden;
}
</style>
