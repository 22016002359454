<template>
  <slick ref="slick" :options="slickOptions" class="slider-news-tovar">
    <state-item
      v-for="tile in media"
      v-bind:key="tile.key"
      item-class="news-tovar-items"
    >
      <event-item :item="tile" />
    </state-item>
  </slick>
</template>

<script>
import Slick from "vue-slick";
import StateItem from "@/components/page/slick/StateItem";
import EventItem from "@/components/page/events/tiles/EventItem";

export default {
  name: "Event",
  data() {
    return {
      slickOptions: {
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 0,
        prevArrow:
          "<div class='slick-prev-action'><img src='" +
          this.arrowUrl("right") +
          "' alt='' /></div>",
        nextArrow:
          "<div class='slick-next-action'><img src='" +
          this.arrowUrl("left") +
          "' alt='' /></div>",
        responsive: [
          {
            breakpoint: 760,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  props: {
    media: Array
  },
  components: { EventItem, StateItem, Slick },
  methods: {
    arrowUrl(arrow) {
      return require("@/assets/img/arrow-" + arrow + ".svg");
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      this.$nextTick(() => {
        this.$refs.slick.reSlick();
      });
    }
  }
};
</script>

<style scoped></style>
