<template>
  <section class="preem-sertificate">
    <div class="sertificate-preem-flex wow fadeInUp">
      <div>
        <img src="@/assets/img/Apple Macbook Space Gray.png" alt="Preem" />
      </div>
      <div>
        <h4>Преимущества подписки</h4>
        <ul>
          <li>Более 1000 часов развивающего видеоконтента</li>
          <li>Одновременный просмотр на любых устройствах</li>
          <li>Максимальное качество изображения и звука</li>
          <li>Отсутствие рекламы</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "BenefitsBlock"
};
</script>

<style scoped></style>
