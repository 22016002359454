<template>
  <div class="header-container fixed-top">
    <header class="header navbar navbar-expand-sm">
      <div class="navbar-nav theme-brand flex-row  text-center">
        <div class="nav-item theme-logo">
          <router-link to="/">
            <img src="@/assets/img/logoheader.svg" class="navbar-logo" alt="logo" />
          </router-link>
        </div>
      </div>

      <search-header :search-params.sync="searchValue" />
    </header>
  </div>
</template>

<script>
import SearchHeader from "@/components/admin/header/SearchHeader";
export default {
  name: "AdminHeader",
  components: { SearchHeader },
  props: {
    searchParams: {
      type: [String, Object]
    }
  },
  computed: {
    searchValue: {
      get() {
        return this.searchParams;
      },
      set(n) {
        this.$emit("update:searchParams", n);
      }
    }
  }
};
</script>

<style scoped>
header {
  margin: 0 !important;
  width: inherit !important;
}

.navbar {
  padding: 0 !important;
}
</style>
